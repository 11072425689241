import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import { IRootState } from "../../interfaces/rootState";
import mainState from "./data-reducer";

const rootReducer = (history: any) => combineReducers<IRootState>({
  mainState: mainState as any,
  router: connectRouter(history),
});

export default rootReducer;
