import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import * as React from "react";
import "./Checkboxes.css";

export interface CheckboxesProps {
  updateMining: any;
  updateCommodity: any;
  userAdvanced?: any;
}

export interface CheckboxesState {
  filtered: object[];
  commodity: any;
  mining_method: any;
}

class Checkboxes extends React.Component<CheckboxesProps, CheckboxesState> {
  constructor(props: CheckboxesProps) {
    super(props);
    this.state = {
      filtered: [],
      commodity: [],
      mining_method: [],
    };
  }
  public componentDidMount() {
    const comm = [{title: "Coal"}, {title: "Metal"}, {title: "Nonmetal"}];
    const mining = [{title: "Surface"}, {title: "Underground"}];
    comm.forEach((auth: any) => {
      auth.checked = true;
    });
    mining.forEach((auth: any) => {
      auth.checked = true;
    });
    this.setState({
      commodity: comm,
      mining_method: mining
    });
  }

  public componentDidUpdate(nextProps: CheckboxesProps) {
      if (nextProps.userAdvanced !== this.props.userAdvanced) {
      const comm = [{title: "Coal"}, {title: "Metal"}, {title: "Nonmetal"}];
      const mining = [{title: "Surface"}, {title: "Underground"}];
      comm.forEach((auth: any) => {
        auth.checked = false;
        this.props?.userAdvanced?.results?.forEach((userInfo: any) => {
          if (userInfo.value === auth.title) {
            auth.checked = true;
          }
        });
      });
      mining.forEach((auth: any) => {
        auth.checked = false;
        this.props?.userAdvanced?.results?.forEach((userInfo: any) => {
          if (userInfo.value === auth.title) {
            auth.checked = true;
          }
        });
      });
      this.setState({
        commodity: comm,
        mining_method: mining
      });
    }
  }

  public filterCommodity(checked: any, index: any, ) {
    const new_comm = this.state.commodity;
    new_comm[index].checked = !checked;
    this.setState({
      commodity: new_comm
    });
    const checkedList: object[] = [];
    this.state.commodity.forEach((auth: any) => {
      if (auth.checked) {
        checkedList.push(auth.title);
      }
    });
    this.props.updateCommodity(checkedList);
  }

  public filterMining(checked: any, index: any, ) {
    const new_mine = this.state.mining_method;
    new_mine[index].checked = !checked;
    this.setState({
      mining_method: new_mine
    });
    const checkedList: object[] = [];
    this.state.mining_method.forEach((auth: any) => {
      if (auth.checked) {
        checkedList.push(auth.title);
      }
    });

    this.props.updateMining(checkedList);
  }

  public render() {
    return (
      <div className="checksContainer">
        <div className="topLevelChecksLeft">
          <h3 className="">Commodity</h3>
          <div className="checkboxLevel">{this.state.commodity.map((facet: any, index: any) => (
            <div key={index} style={{display: "flex"}}>
              <FormControlLabel
                color="default"
                style={{display: "flex", fontSize: "14px"}}
                control={
                  <Checkbox checked={facet.checked} className="check" value={facet.checked} onChange={() => this.filterCommodity(facet.checked, index)}/>
                }
                label={facet.title}
              />
            </div>
          ))}
          </div>
        </div>
        <div className="topLevelChecksRight">
          <h3 className="">Mining Method</h3>
          <div className="checkboxLevel">{this.state.mining_method.map((facet: any, index: any) => (
            <div key={index} style={{display: "flex"}}>
              <FormControlLabel
                color="default"
                style={{display: "flex", fontSize: "14px"}}
                control={
                  <Checkbox checked={facet.checked} className="check" value={facet.checked} onChange={() => this.filterMining(facet.checked, index)}/>
                }
                label={facet.title}
              />
            </div>
          ))}
          <div style={{display: "flex"}}>
              <FormControlLabel
                color="default"
                style={{display: "flex", fontSize: "14px", opacity: 0}}
                control={
                  <Checkbox />
                }
                label=""
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Checkboxes;
