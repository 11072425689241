
import React, { useEffect } from "react";
import { MARGIN_MEDIUM } from "../Shared/layout-constants";
import { useDispatch } from "react-redux";
import { getAdminDashboardData } from "../../actions/adminActions";

const AdminDashboard: React.FC = (props: any) => { 

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAdminDashboardData({}))
      }, []);

    return <div style={{marginTop: MARGIN_MEDIUM}}>
        Admin Dashboard
    </div>

}
export default AdminDashboard