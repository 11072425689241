import { Button, TextField } from "@mui/material/";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as React from "react";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { Navigate, useLocation } from "react-router";
import * as Yup from "yup";
import zxcvbn from "zxcvbn";
import { passwordSetAction } from "../../actions/userActions";
import { IpasswordSetPost } from "../../interfaces/passwordSetPost";
import "./PasswordSet.css";
import { PasswordSetField } from "./PasswordSetField";

export interface Values {
    password: string;
}

export interface State {
    changed: boolean;
}

const passwordSchema = Yup.object().shape({
    password: Yup.string()
        .min(8, "Please Enter a Valid Password")
        .test("password-validation", "Password must include one upper case, one number and be a minimum of 9 characters in length", (value) => {
            if (!value) { return false; }
            const result = zxcvbn(value);
            return result.score >= 3;
        })
        .required("Required"),
});

const PasswordSet: React.FC = (props: any) => {
    const [changed, setChanged] = React.useState(false);
    const dispatch = useDispatch();
    const location = useLocation();

    const newUser = (values: any) => {
        const query = new URLSearchParams(location.search);
        const token = query.get("token");
        const email = query.get("email");
        const frmValues = { token, email, password: values.password };

        dispatch(passwordSetAction({payload: frmValues} as any,
            () => {
                setChanged(true);
            },
            () => {
                setChanged(true);
            },
        )
        );
    };

    if (changed) {
        return <Navigate replace to="/?passwordChanged=true" />;
    }
    return (
        <div className="LoginContainer">
            <div className="spacing" />
            <Formik
                initialValues={{ password: "" }}
                validationSchema={passwordSchema}
                onSubmit={(values) => {
                    newUser(values);
                }}
            >
                {({ errors, touched, values }) => (
                    <Form className="FormContainer">
                        <h2>Set Password</h2>
                        <div className="fields" style={{ marginBottom: "5px" }}>
                            <Field name="password" placeholder="Password" component={PasswordSetField} />
                        </div>
                        {errors.password ? (
                            <div className="passwordError">{errors.password}</div>
                        ) : null}
                        <div style={{ marginTop: "16px" }}>
                            <Button className="signinButton" type="submit">Submit</Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );

};

export default PasswordSet;
