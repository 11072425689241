import { useTheme } from "@emotion/react";
import { useMediaQuery, Box, List, ListItem, ListItemText, AppBar, Toolbar, Typography, IconButton, Drawer, Button } from "@mui/material";
import React, { useState } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch } from "react-redux";

import { logoutAction } from "../../actions/userActions";
import { useNavigate } from "react-router-dom";
import { COLORS } from "../Shared/layout-constants";

interface IResponsiveAppBarProps {
    loggedUser: any;
}

const ResponsiveAppBar = (props: IResponsiveAppBarProps) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [drawerOpen, setDrawerOpen] = useState(false);
    const theme = useTheme() as any;
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const menuItems = ['Search', 'Account'];
    if (props.loggedUser?.isAdmin){
        menuItems.push('Admin')
    }
    menuItems.push('Logout');

    const toggleDrawer = (isOpen: any) => (event: any) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(isOpen);
    };

    const list = () => (
        <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                {menuItems.map((text, index) => (
                    <ListItem component="li" key={text} onClick={() => handleAction(text)}>
                        <ListItemText  primary={text} />
                    </ListItem>
                ))}
            </List>
        </Box>
    );


    function logout() {
        dispatch(logoutAction({}))
        navigate("/");
    }

    function handleAction(name: string) {
        console.log("GO")
        switch (name) {
            case 'Admin':
                navigate("/admin");
                break;
            case 'Search':
                navigate("/search");
                break;
            case 'Account':
                navigate("/profile");
                break;
            case 'Logout':
                logout();
                break;
            default:
                break;
        }
    }

    return (
        <AppBar position="static" style={{backgroundColor: 'white', boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)'}}>
            <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} style={{cursor: 'pointer'}} onClick={() => navigate('')}>
                    <img
                        src="/images/MSHAwise-logo.png"
                        alt="Logo"
                        style={{ maxHeight: '100%', maxWidth: '100%', height: 42, marginTop: 8, width: 'auto' }}
                    />
                </Typography>
                {props.loggedUser?.email && <>
                    {isMobile ? (
                    <>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={toggleDrawer(true)}
                        >
                            <MenuIcon style={{color: COLORS.BRAND_PRIMARY_DARK}} />
                        </IconButton>
                        <Drawer
                            anchor="right"
                            open={drawerOpen}
                            onClose={toggleDrawer(false)}
                        >
                            {list()}
                        </Drawer>
                    </>
                ) : (
                    menuItems.map((menuItem, index) => (
                        <Button onClick={() => handleAction(menuItem)} key={index} style={{textTransform: 'none', fontSize: 18, color: COLORS.BRAND_PRIMARY_DARK, marginLeft: 16}}>{menuItem}</Button>
                    ))
                )}
                </>}

            </Toolbar>
        </AppBar>
    );
};

export default ResponsiveAppBar;