import { Button, TextField } from "@mui/material/";
import { Field, Form, Formik } from "formik";
import * as React from "react";
import { connect } from "react-redux";
import { userAction } from "../../actions/userActions";
import { IuserPost } from "../../interfaces/userPost";
import "./ThankYou.css";

export interface Props {
    userInfo: any;
  }

export interface Values {
  firstName: string;
  lastName: string;
  email: string;
}

class ThankYou extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
      }

    public newUser(values: any) {
        this.props.userInfo({username: values.firstName, email: values.email, password: values.password});
    }

  public render() {
    let line1 = "Welcome to MSHAwise! Thanks for signing up!";
    let line2 = "Please check your email for a link to set your password and log in.";
    if (window.location.search === "?passwordReset=true") {
        line1 = "Thank you! Your request for password change has been accepted.";
        line2 = "Please check your email for a link to reset your password.";

    }
    return (
    <div className="LoginContainer">
        <div className="spacing"/>
        <div className="thankYouContainer">
            <div className="thankYouPadding">
                <h2>{line1}</h2>

                <h2>{line2}</h2>
            </div>
        </div>
    </div>
  );
 }
}

const mapDispatchToProps = (dispatch: any) => ({
    userInfo: (userInfo: IuserPost, onSuccess = null, onFail = null) => dispatch(userAction(userInfo, onSuccess, onFail)),
  });

export default connect(null, mapDispatchToProps)(ThankYou);
