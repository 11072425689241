import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  overrides: {
    MuiCheckbox: {
      colorPrimary: "black",
      colorSecondary: "white",
      root: {
        colorPrimary: "black",
        colorSecondary: "white",
      },
    },
  },
});

export default theme;
