import React from 'react';
import { Drawer, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

interface IDrawerComponent {
  children: React.ReactNode;
  triggerButton: React.ReactNode;
  isOpen: boolean;
  toggleDrawer: (open: boolean) => void;
  anchor: "left" | "right";
}

const DrawerComponent: React.FC<IDrawerComponent> = ({ children, isOpen, toggleDrawer, anchor, triggerButton }) => {
  return (
    <div>
      <IconButton onClick={() => toggleDrawer(true)}>
        {triggerButton}
      </IconButton>
      <Drawer anchor={anchor} open={isOpen} onClose={() => toggleDrawer(false)}>
        <div
          role="presentation"
          onClick={() => toggleDrawer(false)}
          onKeyDown={() => toggleDrawer(false)}
        >
          {children}
        </div>
      </Drawer>
    </div>
  );
};

export default DrawerComponent;
