import React from 'react';
import { makeStyles } from '@mui/styles';
import { Typography, Modal, Backdrop, Fade } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme: any) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleOptions: {
    display: 'block',
    fontSize: '12px',
    fontWeight: 'bold',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    margin: theme.spacing(0, 1), // Reduced margin for smaller screens
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2.6),
      paddingTop: theme.spacing(0.5),
    },
  },
  sectionDesktop: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline',
    padding: theme.spacing(1), // Reduced padding
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  paper: {
    position: 'absolute',
    textAlign: 'center',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const Footer = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const terms = () => navigate('/termsAndConditions');

  return (
    <div>
      <div className={classes.sectionDesktop}>
        <Typography className={classes.titleOptions}>&copy; {new Date().getFullYear()} MSHAwise</Typography>
        <Typography className={classes.titleOptions}>Phone: (202) 262-4081</Typography>
        <Typography className={classes.titleOptions}>Email: <a style={{textDecoration: 'none', color: 'inherit'}} href="mailto:mark@mshawise.com">mark@mshawise.com</a></Typography>
        <Typography className={classes.titleOptions} onClick={terms}>Terms and Conditions</Typography>
        {/* <Typography className={classes.titleOptions} onClick={handleOpen}>Pricing</Typography> */}
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">Coming Soon!</h2>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default Footer;
