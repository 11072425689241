import { Box, Button, LinearProgress } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Icon from "@mui/material/Icon";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import * as React from "react";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import { solrRelatedAction } from "../../actions/solrActions";
import { IRootState } from "../../interfaces/rootState";
import { ISolrRelatedQuery } from "../../interfaces/solrRelatedQuery";
import ReadMore from "../ReadMore/ReadMore";
import "./List.css";
import FacetList, { IFacetItem } from "./FacetList";
import { REGULATIONS_MAP } from "../../constants";
import DrawerComponent from "../General/DrawerComponent";
import { ChevronLeft, FilterList} from "@mui/icons-material";

export interface ListProps {
  resetAllEntities: any;
  items: object[];
  facets: any;
  term: string;
  singleDocView: any;
  updateAuthorityLevel: any;
  updateGuidance: any;
  updateDocType: any;
  updateRegsType: any;
  updatePage: any;
  numFound: number;
  solrQuick: any;
  sort: any;
  updateSort: any;
  numOfResults: number;
  updateNumOfResults: any;
  solrRelated: any;
  mainState: any;
  offset: number;
  authorityLevel: any;
  subGuidance: any;
  regulations: any;
  regList: any;
  regsFacet: any;
  context: string;
  isSearching: boolean;
  entityPerson: IFacetItem[];
  entityLocation: IFacetItem[];
  entityOrganization: IFacetItem[];
  updateEntityPerson: any;
  updateEntityLocation: any;
  updateEntityOrganization: any;
  originalDocType: any;
}

export const guidanceMap = {
  ph: "Procedure Handbooks",
  ppl: "Program Policy Letter",
  pil: "Procedure Instruction Letter",
  ppm: "Program Policy Manuals",
  pib: "Program Information Bulletin",
  cg: "Compliance Guides",
  faq: "FAQs"
} as any

export interface ListState {
  filtered: object[];
  openedPopoverId: any;
  anchorEl: any;
  regMap: any;
  showDocId: boolean
  facetDrawerOpen: boolean
}

class SolrResultList extends React.Component<ListProps, ListState> {

  constructor(props: ListProps) {

    const queryParams = new URLSearchParams(window.location.search);
    const showDocId = queryParams.get('showid');
    super(props);
    this.filterSort = this.filterSort.bind(this);
    this.filterNumOfResults = this.filterNumOfResults.bind(this);
    this.handlePopoverOpen = this.handlePopoverOpen.bind(this);
    this.handleRegulationsPopoverOpen = this.handleRegulationsPopoverOpen.bind(this);
    this.handlePopoverClose = this.handlePopoverClose.bind(this);
    this.handlePageUpdate = this.handlePageUpdate.bind(this);

    this.state = {
      filtered: [],
      regMap: REGULATIONS_MAP,
      openedPopoverId: false,
      anchorEl: null,
      showDocId: showDocId ? true : false,
      facetDrawerOpen: false
    };
  }
  public componentDidMount() {
    const filtered = this.props.items;
    filtered.forEach((filt: any) => {
      filt.anchorEl = null;
      filt.open = false;
    });

    this.setState({
      filtered: this.props.items,
    });
  }

  public componentDidUpdate(prevProps: ListProps) {
    // Check if the items prop has changed
    if (this.props.items !== prevProps.items) {
      this.setState({
        filtered: this.props.items,
      });
    }
  }

  public handlePopoverOpen(event: any, item: any) {
    const docIds = [];
    if (item.related_up) {
      docIds.push(item.related_up.join(""));
    }
    if (item.related_down) {
      docIds.push(item.related_down.join(""));
    }
    if (item.related_peer) {
      docIds.push(item.related_peer.join(""));
    }
    if (docIds.length) {
      this.props.solrRelated({ related_document_ids: docIds });
      this.setState({
        openedPopoverId: item.id,
        anchorEl: event.target,
      });
    }
  }

  public handleRegulationsPopoverOpen(event: any, item: any) {
    if (item.citation_insights) {
      this.setState({
        openedPopoverId: item.id,
        anchorEl: event.target,
      });
    }
  }

  public handlePopoverClose() {
    this.setState({
      openedPopoverId: null,
      anchorEl: null,
    });
  }

  public filterType(checked: any, index: any, theList: any, updateFunction: any) {
    const listToUpdate = theList;
    if (listToUpdate) {
      listToUpdate[index].checked = !checked;
      updateFunction(listToUpdate);
    }

  }

  public selectAll(theList: any, theUpdate: any) {
    const new_auth = theList;
    Object.keys(new_auth).forEach((v) => new_auth[v].checked = true);
    theUpdate(new_auth);
  }

  public selectOnly(index: any, theList: any, theUpdate: any) {
    const new_auth = theList;
    Object.keys(new_auth).forEach((v) => new_auth[v].checked = false);
    new_auth[index].checked = true;
    theUpdate(new_auth);
  }

  public filterSort(event: any) {
    this.props.updateSort(event.target.value);
  }

  public filterNumOfResults(event: any) {
    this.props.updateNumOfResults(event.target.value);
  }

  public handlePageUpdate(data: any) {
    const selected = data.selected;
    const offset = Math.ceil(selected * this.props.numOfResults);
    this.props.updatePage(offset);
  }
  // tslint:disable-next-line
  public compare(a: any, b: any) {
    // tslint:disable-next-line
    if (parseInt(a.count) > parseInt(b.count)) {
      return -1;
    }
    // tslint:disable-next-line
    if (parseInt(a.count) < parseInt(b.count)) {
      return 1;
    }
    return 0;
  }

  public renderHighlight = (item: any) => {

    // reverse order of preference
    const highlightFields = ["_text_ngram_", "_text_"];

    let returnString = "";

    for (let i = 0; i < highlightFields.length; i++) {
      if (item?.highlighting?.[highlightFields[i]]) {
        returnString = item.highlighting?.[highlightFields[i]].join(" ...").replace(/em>/gi, "b>");
      }
    }
    return returnString;
  }

  public prepareAuthorityLevelFacets = () => {
    const authFacets = [...this.props.authorityLevel]
    const subguidanceFacets = this.props.subGuidance?.map((x: any) => { return { title: x.title, label: guidanceMap[x.title], count: x.count, checked: x.checked, indent: true, group: 'guidance' } }) || []
    let returnItems = authFacets.concat(subguidanceFacets)
    returnItems = returnItems.filter((x: any) => x.title.toLowerCase() !== 'na')
    return returnItems
  }

  public renderFacitedNav() {


    const containerStyle = {} as any
    if (this.props.isSearching){
      containerStyle.visibility = 'hidden';
    }
    // if (this.props.context === "caselaw"){
    //   console.log("caselaw DEBUG this.props.entityPersonCaseLaw", this.props.entityPerson)
    //   console.log("caselaw DEBUG this.props", this.props)
    //   console.log("caselaw DEBUG this.state", this.state)
    // }

    return (
      <div className={"facetedNavInner"} style={containerStyle}>
        {this.props.context === "regulations" && this.props.regList &&
          <>
            <FacetList
              identifier={'regulations'}
              hide_unchecked={true}
              minimized_count={20}
              maximized_count={40}
              facet_items={this.props.regList} //the name is not complete here?
              title={'Regulations'}
              handleFacetChange={(identifier, facets) => {
                if (facets.length === 0) {
                  this.props.resetAllEntities();
                  return;
                }
                const updatedRegFacet = { ...this.props.regsFacet }
                facets.forEach((x) => {
                  updatedRegFacet[x.title] = { checked: x.checked }
                })
                this.props.updateRegsType(updatedRegFacet)
              }}
              info_text=""
            />

          </>}

          {this.props.context === "documents" && this.props.authorityLevel.length > 0 && <>
          <FacetList
            identifier={'authority_level'}
            hide_unchecked={true}
            minimized_count={100}
            maximized_count={100}
            facet_items={this.prepareAuthorityLevelFacets()} //the name is not complete here?
            title={'Authority Level'}
            handleFacetChange={(identifier, facets) => {

              if (facets.length === 0) {
                this.props.resetAllEntities();
                return;
              }

              let authFacets = facets.filter((x: any) => x.group !== 'guidance')
              let guidanceFacets = facets.filter((x: any) => x.group === 'guidance')
              const authGuidanceIndex = authFacets.findIndex((x: any) => x.title === 'guidance')

              //TODO is this necessary
              authFacets[authGuidanceIndex].count = guidanceFacets.reduce((acc, x) => {
                if (x.checked) {
                  return acc + x.count
                }
                else {
                  return acc
                }
              }, 0)

              this.props.updateAuthorityLevel(authFacets)
              this.props.updateGuidance(guidanceFacets)

            }}
            info_text=""
          />
          <br/><br/>
        </>}

        {(this.props.context === "documents" || this.props.context === "caselaw") && (this.props.entityPerson.length > 0 || this.props.entityOrganization.length > 0 || this.props.entityLocation.length > 0) &&
          <div>
            <FacetList
              identifier={'entity_person'}
              hide_unchecked={true}
              minimized_count={4}
              maximized_count={10}
              facet_items={this.props.entityPerson} //the name is not complete here?
              title={'People'}
              handleFacetChange={(identifier, facets) => {

                if (facets.length === 0) {
                  this.props.resetAllEntities();
                  return;
                }

                this.props.updateEntityPerson(facets)
                // this.setState({ entityPerson: facets })
              }}
              info_text="People entities are the names of people mentioned in the documents. They are extracted using a named entity recognition model. There may be more results then listed, if all are selected the documents will not be filtered by the extracted entities."
            />
            <br /><br />
            <FacetList
              identifier={'entity_location'}
              hide_unchecked={true}
              minimized_count={4}
              maximized_count={10}
              facet_items={this.props.entityLocation} //the name is not complete here?
              title={'Locations'}
              handleFacetChange={(identifier, facets) => {

                if (facets.length === 0) {
                  this.props.resetAllEntities();
                  return;
                }

                this.props.updateEntityLocation(facets)
                // this.setState({ entityLocation: facets })
              }}
              info_text="Location entities are the names of locations mentioned in the documents. They are extracted using a named entity recognition model. There may be more results then listed, if all are selected the documents will not be filtered by the extracted entities."
            />
            <br /><br />
            <FacetList
              identifier={'entity_organization'}
              hide_unchecked={true}
              minimized_count={4}
              maximized_count={10}
              facet_items={this.props.entityOrganization} //the name is not complete here?
              title={'Organizations'}
              handleFacetChange={(identifier, facets) => {

                if (facets.length === 0) {
                  this.props.resetAllEntities();
                  return;
                }
                this.props.updateEntityOrganization(facets)
                // this.setState({ entityOrganization: facets })
              }}
              info_text="Organization entities are the names of organizations mentioned in the documents. They are extracted using a named entity recognition model. There may be more results then listed, if all are selected the documents will not be filtered by the extracted entities."
            />
          </div>}



        {this.props.context === "documents" && this.props.originalDocType && <>
          <br /><br />

          <FacetList
            identifier={'document_format'}
            hide_unchecked={true}
            minimized_count={100}
            maximized_count={100}
            facet_items={this.props.originalDocType} //the name is not complete here?
            title={'Document Format'}
            handleFacetChange={(identifier, facets) => {

              if (facets.length === 0) {
                this.props.resetAllEntities();
                return;
              }

              this.props.updateDocType(facets)

            }}
            info_text=""
          />

        </>}

      </div>
    );
  }

  public toggleFacetDrawer = (open: boolean) => {
    this.setState({ facetDrawerOpen: open })
  }

  public render() {

    const { anchorEl, openedPopoverId } = this.state;
    const open = Boolean(anchorEl);
    const related = this.props.mainState.solrRelated;
    let displayOffset = this.props.offset + this.props.numOfResults;
    if (displayOffset > this.props.numFound) {
      displayOffset = this.props.numFound;
    }

    return (
      <div onClick={this.handlePopoverClose} onScroll={this.handlePopoverClose}>
        <div className="facetedNavMobile">
          <DrawerComponent triggerButton={<div><FilterList/></div>} anchor="left" isOpen={this.state.facetDrawerOpen} toggleDrawer={this.toggleFacetDrawer}>
            <ChevronLeft style={{ fontSize: 32, cursor: 'pointer' }} onClick={() => { this.toggleFacetDrawer(false) }} />
            <div className={"facitedNav"} style={{ maxWidth: '80vw' }}>
              {this.renderFacitedNav()}
            </div>
          </DrawerComponent>
        </div>

        <div className="BottomHalf">
          <div className="facetedNavDesktop facitedNav">
            {this.renderFacitedNav()}
          </div>
          <div className="listContainer">
            <div>
              {this.props.context === "regulations" && this.props.regulations &&
                this.props.regulations.response &&
                this.props.regulations.response.docs[0] &&
                this.props.regulations.response.docs[0] && (
                  <div>
                    <div className="regsHeaderContainer"><h3 className="regsTitle">Regulations</h3>
                      <Icon className="iconWrapper"><img alt='regulation_icon' className="icon" src={`/images/regulation.svg`} /></Icon>
                    </div>
                    <div className="blueLine" />
                    <div className="regsList">
                      {this.props.regulations.response.docs.map((item: any) => (
                        <div key={item.id}>
                          <div className="listItem" aria-owns={open ? "mouse-over-popover" : undefined} aria-haspopup="true">
                            <div className="listHeader">
                              <div className="listTitle"><b style={{ padding: "20px 0" }} dangerouslySetInnerHTML={{ __html: item.friendly_reg_label }}></b>
                                <div className="listTitle">{item.reg_title}</div></div>
                              <div onMouseEnter={(e) => this.handleRegulationsPopoverOpen(e, item)} className={item.citation_insights ? "brightIconWrapper" : "iconWrapper"}>
                                <Icon><img alt='regulation_popover' className="icon" src={`/images/lightbulb.svg`} /></Icon>
                              </div>
                            </div>
                            <div className="listStatue">{item.subpart}</div>&nbsp;
                            {/* <div className="listText" dangerouslySetInnerHTML={{ __html: item.reg_body ? item.reg_body.replace(/em>/gi, "b>") : ""}} /> */}
                            <ReadMore lines={3} more="Expand" less="Collapse" children={item.reg_body} />
                          </div>
                          <Popover
                            id="mouse-over-popover"
                            className=""
                            open={openedPopoverId === item.id}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            onMouseLeave={this.handlePopoverClose}
                            disableRestoreFocus
                          >
                            {item.citation_insights && (
                              <div onMouseLeave={this.handlePopoverClose} onMouseEnter={(e) => this.handleRegulationsPopoverOpen(e, item)}>
                                <h3 className="relatedTitle">Compliance Insights</h3>
                                <div className="relatedTop">
                                  <div className="relatedContainer">
                                    <div className="listHeader">
                                      <div className="listTitle"><b style={{ padding: "20px 0", color: "#034864" }}>{item.citation_insights}</b></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Popover>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
            </div>

            {this.props.context !== "regulations" &&
              <>
                {this.state.filtered.length > 0 && (
                  <div className="dropdownContainer">
                    <FormControl variant="filled" className="selectFields">
                      <Select
                        value={this.props.sort}
                        onChange={this.filterSort}
                      >
                        <MenuItem value={"best"}>Sort By: Best Match</MenuItem>
                        <MenuItem value={"date"}>Sort By: Recently Updated</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl variant="filled" className="selectFields">
                      <Select
                        value={this.props.numOfResults}
                        onChange={this.filterNumOfResults}
                      >
                        <MenuItem value={10}>Results Per Page: 10</MenuItem>
                        <MenuItem value={20}>Results Per Page: 20</MenuItem>
                        <MenuItem value={50}>Results Per Page: 50</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                )}
                {this.state.filtered.length > 0 && !this.props.isSearching && (
                  <div>
                    <Typography style={{ padding: "5px 0 0 10px" }}>
                      Displaying {this.props.offset + 1} to {displayOffset} of <b>{this.props.numFound} documents for &#8220;{this.props.term}&#8221;</b>
                    </Typography>
                  </div>
                )}
                {this.props.isSearching && <Box sx={{ width: "100%" }}>
                  <LinearProgress />
                </Box>}
                <div>
                  {!this.props.isSearching && this.state.filtered.map((item: any) => (
                    <div key={item.id}>
                      <div className="listItem" aria-owns={open ? "mouse-over-popover" : undefined} aria-haspopup="true" onClick={() => this.props.singleDocView(item)}>
                        <div className="listHeader">
                          <div className="listTitle"><b style={{ cursor: 'pointer', padding: "20px 0", color: "#034864" }}>{item.friendly_title}{this.state.showDocId ? <span style={{ color: 'red' }}> ({item.id})</span> : null}</b>
                            <span> - {new Date(item.updated_date).toDateString().split(" ").slice(1).join(" ")}</span></div>
                          <div onMouseEnter={(e) => this.handlePopoverOpen(e, item)} className={item.related_down || item.related_up || item.related_peer ? "brightIconWrapper" : "iconWrapper"}>
                            {item.authority_level && <Icon><img alt='authority_level' className="icon" src={`/images/${item.authority_level ?? [].join(", ")}.svg`} /></Icon>}
                          </div>
                        </div>
                        <div className="listStatue">
                          {item.authority_level ?? [].join(", ")}
                          {(item.guidance_type?.length > 0) ? ` - ${item.guidance_type.join(", ").toUpperCase()}` : ""}
                        </div>&nbsp;
                        {/* <div className="listText" dangerouslySetInnerHTML={{ __html: item.highlighting._text_ ? item.highlighting._text_.join(" ...").replace(/em>/gi, "b>") : ""}} />
                     */}
                        <div className="listText" dangerouslySetInnerHTML={{ __html: this.renderHighlight(item) }} />
                      </div>
                      <Popover
                        id="mouse-over-popover"
                        className=""
                        open={openedPopoverId === item.id}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        disableRestoreFocus
                      >
                        <div onMouseLeave={this.handlePopoverClose}>
                          <h3 className="relatedTitle">Related</h3>
                          {related && related.response && related.response.docs && (
                            <div className="relatedTop">
                              {related.response.docs.map((rel: any) => (
                                <div className="relatedContainer" key={rel.id}>
                                  <div className="listHeader">
                                    <div className="listTitle" style={{ cursor: "pointer" }} onClick={() => this.props.singleDocView(rel)}><b style={{ padding: "20px 0", color: "#034864" }}>{rel.friendly_title}</b>
                                      <span> - {new Date(rel.updated_date).toDateString().split(" ").slice(1).join(" ")}</span></div>
                                    <Icon className="relatedIconWrapper"><img alt='related_docs' className="iconPopout" src={`/images/${rel.authority_level.join("")}.svg`} /></Icon>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </Popover>
                    </div>
                  ))}
                </div>
                <ReactPaginate
                  previousLabel={"◀︎"}
                  nextLabel={"▶"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={this.props.numFound / this.props.numOfResults}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={this.handlePageUpdate}
                  activeLinkClassName={"pagination-active"}
                />
                {this.state.filtered.length > 0 && !this.props.isSearching && (
                  <div>
                    <Typography style={{ padding: "5px 0 0 10px" }}>
                      Displaying {this.props.offset + 1} to {displayOffset} of <b>{this.props.numFound} documents for &#8220;{this.props.term}&#8221;</b>
                    </Typography>
                  </div>
                )}
              </>}
          </div>
          <div className="hintNav">
            {this.props.solrQuick &&
              this.props.solrQuick.response &&
              this.props.solrQuick.response.docs[0] &&
              this.props.solrQuick.response.docs[0].search_result && (
                <div>
                  <h3 className="quickHeader">Compliance Insight</h3>
                  <div className="quickSearch"><div>Based on your search for</div><div><b>"{this.props.term}"</b></div></div>
                  {this.props.solrQuick.response.docs[0].search_result.map((result: any, index: number) => (
                    <div className="quickResult" key={index}>
                      <div dangerouslySetInnerHTML={{ __html: result }}></div>
                    </div>
                  ))}
                  {this.props.solrQuick.response.docs[0].sources &&
                    this.props.solrQuick.response.docs[0].sources.length > 0 && (
                      <div className="quickSource">
                        Sources: {this.props.solrQuick.response.docs[0].sources.join(", ")}
                      </div>
                    )}
                </div>
              )}

          </div>

        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  solrRelated: (solrRelated: ISolrRelatedQuery, onSuccess = null, onFail = null) => dispatch(solrRelatedAction(solrRelated, onSuccess, onFail)),
});

const mapStateToProps = ({ mainState }: IRootState) => ({
  mainState,
});

export default connect(mapStateToProps, mapDispatchToProps)(SolrResultList);
