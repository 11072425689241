import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import {applyMiddleware, compose, createStore} from "redux";
import createSagaMiddleware from "redux-saga";
import { IRootState } from "../interfaces/rootState";
import reducer from "../store/reducers/reducers";
import rootSaga from "../store/sagas/rootSaga";
export const history = createBrowserHistory();
/**
 * saga middleware
 */
const sagaMiddleware = createSagaMiddleware();

/**
 * default middlewares,
 * THIS MIDDLEWARE SHOULD BE SHARED FOR MOBILE AND WEB
 */
const defaultMiddlewares = [
    sagaMiddleware,
];

/**
 * function to compose middlewares
 * @param middlewares
 */
const composedMiddlewares = (middlewares: any) =>
    compose(
        applyMiddleware(routerMiddleware(history), ...defaultMiddlewares as any, ...middlewares),
        // window['__REDUX_DEVTOOLS_EXTENSION__'] ? window['__REDUX_DEVTOOLS_EXTENSION__']() : f => f
    );

/**
 * initialize store,
 * add initialState if it need
 * add middlewares if it need
 * @param middlewares
 */
const initialize = (initialState?: IRootState, middlewares = []) => {
    const store = createStore(reducer(history),
        composedMiddlewares(middlewares),
    );
    sagaMiddleware.run(rootSaga);

    return store;

};

export default initialize;
