import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";
import { COLORS } from "../../constants/layout-enums";
// const useStyles = makeStyles((theme: any) => ({
//   backdrop: {
//     zIndex: theme.zIndex.drawer + 1,
//     color: "#fff",
//   },
// }));

interface ILoadingProps {
  loading: boolean;
  noBackdrop?: boolean;
  noSpinner?: boolean;
}

const Loading: React.FC<ILoadingProps> = (props) => {
  // const classes = useStyles({});
  return (
    <>
      {props.noBackdrop === true ? <Backdrop style={{ zIndex: 5000, backgroundColor: "transparent" }} open={props.loading}>
        {props.noSpinner === true ? null : <CircularProgress color="inherit" />}
      </Backdrop> : <Backdrop style={{ zIndex: 5000, color: COLORS.RED_BRAND_PRIMARY }} open={props.loading}>
        {props.noSpinner === true ? null : <CircularProgress color="inherit" />}
      </Backdrop>}
    </>

  );
};

export default Loading;
