import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { get } from "lodash";
import { HttpResponse, HttpStatus } from "../interfaces/HttpResponse";
import { PublicResource } from "../interfaces/PublicResource";

const API_URL: string = process.env.REACT_APP_SERVER_DOMAIN || "https://endpointnotfound";
interface IAxiosHttpResponseWrapper<T> {
  data: T;
  errors: unknown[];
}

export const objectToQuery = (obj: any) => {
  return (
    "?" +
    Object.keys(obj)
      .map((key) => key + "=" + obj[key])
      .join("&")
  );
};

export const objectToQueryExcludeUndefined = (obj: any) => {
  return (
    "?" +
    Object.keys(obj)
      .map((key) => {
        if (obj[key]) {
          return key + "=" + obj[key];
        }
        return null;
      })
      .filter((item) => item !== null)
      .join("&")
  );
};

export default class HttpClient {
  private url: string;

  private publicResources: PublicResource[] = [];

  private currentResource: string;

  private axios = axios.create();

  constructor() {
    this.url = `${API_URL}`;
    this.currentResource = "";
    this.axios.interceptors.request.use(this.requestInterceptor.bind(this) as any);
    this.axios.interceptors.response.use(this.onResponse, this.onResponseError);
  }

  public setPublicResources(resources: PublicResource[]): void {
    this.publicResources = resources;
  }

  public async get<Data>(resource?: string, options?: AxiosRequestConfig): Promise<HttpResponse<any>> {
    this.currentResource = resource ?? "";
    const endpoint = resource ? `${this.url}/${resource}` : this.url;

    try {
      const response = await this.axios.get<{ data: Data; errors: unknown[] }>(endpoint, options);
      return {
        status: response.status as HttpStatus,
        data: response.data,
        errors: [],
      };
    } catch (e) {
      const error = e as AxiosError<{ error: string }>;
      if (get(error, "response.status", 500) === 401) {
        localStorage.clear();
        window.location.href = "/";
      }
      throw new Error(error.response?.data.error);
    }
  }

  public async getV2<Data>(resource: string, params: any, options?: AxiosRequestConfig): Promise<HttpResponse<any>> {

    this.currentResource = resource;
    let endpoint = `${this.url}/${resource}`;
    params.cookie = window.localStorage.cookie
    endpoint += objectToQuery(params);
    try {
      const response = await this.axios.get<{ data: Data; errors: unknown[] }>(endpoint, options);
      return {
        status: response.status as HttpStatus,
        data: response.data,
        errors: [],
      };
    } catch (e) {
      const error = e as AxiosError<{ error: string }>;
      if (get(error, "response.status", 500) === 401) {
        localStorage.clear();
        window.location.href = "/";
      }
      throw new Error(error.response?.data.error);
    }
  }

  public async getFile<Data>(resource?: string): Promise<HttpResponse<any>> {
    this.currentResource = resource ?? "";
    const endpoint = resource ? `${this.url}/${resource}` : this.url;

    try {
      const options: AxiosRequestConfig = { responseType: "blob" };
      const response = await this.axios.get<{ data: Data; errors: unknown[] }>(endpoint, options);
      return {
        status: response.status as HttpStatus,
        data: response.data,
        errors: [],
      };
    } catch (e: any) {
      const error = e;
      if (error.response?.data.error) {
        throw new Error(error.response?.data.error);
      } else if (error.response.status === 401) {
        throw new Error("Unauthorized resource access.");
      } else {
        throw new Error("An unexpected error ocurried.");
      }
    }
  }

  public async uploadFiles<Data>(resource?: string, data?: unknown, options?: AxiosRequestConfig): Promise<HttpResponse<any>> {
    this.currentResource = resource ?? "";
    const endpoint = resource ? `${this.url}/${resource}` : this.url;
    try {
      const options: AxiosRequestConfig = {
        headers: { "Content-Type": "multipart/form-data" },
      };

      const response = await this.axios.post<IAxiosHttpResponseWrapper<Data>>(endpoint, data, options);

      return {
        status: response.status as HttpStatus,
        data: response.data,
        errors: [],
      };
    } catch (e) {
      console.log(e);
      throw new Error("file upload error");
    }
  }

  public async postFormData<Data>(resource?: string, data?: unknown, options?: AxiosRequestConfig): Promise<HttpResponse<any>> {
    this.currentResource = resource ?? "";
    const endpoint = resource ? `${this.url}/${resource}` : this.url;
    try {
      const response = await this.axios.post<IAxiosHttpResponseWrapper<Data>>(endpoint, data, options);
      return {
        status: response.status,
        data: response.data,
        errors: response.data.errors,
      };
    } catch (e) {
      const error = e as AxiosError<{ error: string }>;
      throw new Error(error.response?.data.error);
    }
  }

  public async post<Data>(resource?: string, data?: unknown, options?: AxiosRequestConfig): Promise<HttpResponse<any>> {
    this.currentResource = resource ?? "";
    const endpoint = resource ? `${this.url}/${resource}` : this.url;
    try {
      const response = await this.axios.post<IAxiosHttpResponseWrapper<Data>>(endpoint, data, options);
      return {
        status: response.status,
        data: response.data,
        errors: response.data.errors,
      };
    } catch (e: any) {
      const error = e as any;
      if (error.response?.data.error) {
        throw new Error(error.response?.data.error);
      } else if (error.response?.data.errors) {
        throw new Error(error.response?.data.errors[0]);
      } else {
        throw new Error("An unexpected error ocurried.");
      }
    }
  }

  public async patch<Data>(resource?: string, data?: unknown, options?: AxiosRequestConfig): Promise<HttpResponse<Data>> {
    const endpoint = resource ? `${this.url}/${resource}` : this.url;
    try {
      const response = await this.axios.patch<IAxiosHttpResponseWrapper<Data>>(endpoint, data, options);
      return {
        status: response.status,
        data: response.data.data,
        errors: response.data.errors,
      };
    } catch (e) {
      const error = e as AxiosError<{ error: string }>;
      throw new Error(error.response?.data.error);
    }
  }

  public async put<Data>(resource?: string, data?: unknown, options?: AxiosRequestConfig): Promise<HttpResponse<any>> {
    const endpoint = resource ? `${this.url}/${resource}` : this.url;
    try {
      const response = await this.axios.put<IAxiosHttpResponseWrapper<Data>>(endpoint, data, options);
      return {
        status: response.status,
        data: response.data,
        errors: response.data.errors,
      };
    } catch (e) {
      const error = e as AxiosError<{ error: string }>;
      throw new Error(error.response?.data.error);
    }
  }

  public async delete<Data>(resource?: string, options?: AxiosRequestConfig): Promise<HttpResponse<any>> {
    const endpoint = resource ? `${this.url}/${resource}` : this.url;
    try {
      const response = await this.axios.delete<IAxiosHttpResponseWrapper<Data>>(endpoint, options);
      return {
        status: response.status,
        data: response.data,
        errors: response.data.errors,
      };
    } catch (e) {
      const error = e as AxiosError<{ error: string }>;
      throw new Error(error.response?.data.error);
    }
  }

  private async onResponse(response: AxiosResponse) {
    // console.info(`[response] [${JSON.stringify(response)}]`);
    // console.log('response.status', response.status)
    return response;
  }

  private async onResponseError(error: AxiosError) {
    // console.error(`[response error] [${JSON.stringify(error)}]`);
    // console.log('error.code', error.response)
    // if (error?.response?.status === 401){
    //   window.location.href = '/'
    // }
    return Promise.reject(error);
  }

  private async requestInterceptor(config: AxiosRequestConfig) {
    if (this.publicResources.length > 0) {
      const isResourcePublic = !!this.publicResources.find(
        (r) => r.resource === this.currentResource && r.method === config.method,
      );
      if (isResourcePublic) {
        return config;
      }
    }
    const token = localStorage.getItem("jwt");
    config.headers = { Authorization: `bearer ${token}` };
    return config;
  }
}
