import { ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";
import React, { Component } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import App from "./components/App/App";
import initializeReduxStorage, { history } from "./config/store";
import "./index.css";
import theme from "./theme";

// init redux storage
const store = initializeReduxStorage();

// const actions = bindActionCreators({ clearAuthentication }, store.dispatch);
// setupAxiosInterceptors(() => actions.clearAuthentication('login.error.unauthorized'));

const container = document.getElementById("root");
const root = createRoot(container!);
const notistackRef = React.createRef<any>();

root.render(
    <ThemeProvider theme={theme}>
        <SnackbarProvider
            ref={notistackRef}
            maxSnack={3}
            autoHideDuration={3000}
            anchorOrigin={{ horizontal: "right", vertical: "top" }}
            action={(key) => <span></span>}
        >
            <Provider store={store}>
                <App />
            </Provider>
        </SnackbarProvider>
    </ThemeProvider>
);
