import React, { Component } from "react";
import Truncate from "react-truncate";

export interface Props {
    children: any;
    lines: number;
    more: string;
    less: string;

  }

export interface State {
    truncated: any;
    expanded: any;
  }
class ReadMore extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            expanded: false,
            truncated: false
        };

        this.handleTruncate = this.handleTruncate.bind(this);
        this.toggleLines = this.toggleLines.bind(this);
    }

    public handleTruncate(truncated: any) {
        if (this.state.truncated !== truncated) {
            this.setState({
                truncated
            });
        }
    }

    public toggleLines(event: any) {
        event.preventDefault();

        this.setState({
            expanded: !this.state.expanded
        });
    }

    public render() {
        const {
            children,
            more,
            less,
            lines
        } = this.props;

        const {
            expanded,
            truncated
        } = this.state;

        return (
            <div style={{fontSize: "12px", textAlign: "left"}}>
                <Truncate
                    lines={!expanded && lines}
                    ellipsis={(
                        <span>... <a href="#" onClick={this.toggleLines}>{more}</a></span>
                    )}
                    onTruncate={this.handleTruncate}
                >
                    {children}
                </Truncate>
                {!truncated && expanded && (
                    <span> <a href="#" onClick={this.toggleLines}>{less}</a></span>
                )}
            </div>
        );
    }
}

export default ReadMore;
