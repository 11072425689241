
  export enum COLORS {
    WHITE_BACKGROUND = 'white',
    RED_BRAND_PRIMARY = '#ed1c24',
    BRAND_PRIMARY = '#00a49e',
    BRAND_PRIMARY_OPACITY20 = '#1c75bc33',
    BRAND_PRIMARY_DARK = '#005754',
    WHITE_PAPER = '#EDEDED',
    GREY_DARK = '#555555',
    MODAL_BACKGROUND = "rgba(248, 250, 251, 0.6)",
  }

  
  export const MARGIN_SMALL = 8;
  export const MARGIN_MEDIUM = 16;
  export const MARGIN_LARGE = 32;
  
  export const TABLE_DEFAULT_PAGE_SIZE = 15;
  export const TABLE_DEFAULT_PAGE_SIZES = [5, 15, 30, 50, 100]