import InputBase from "@mui/material/InputBase";
import TextField from "@mui/material/TextField";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import { FieldProps } from "formik";
import * as React from "react";

export const UserEditField: React.FC<FieldProps & TextFieldProps> = ({
  value,
  field,
  disabled
}) => {
  // TODO DID THIS BREAK
  return <InputBase disabled={disabled} autoFocus {...field} />;
};
