import { Button } from "@mui/material/";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as React from "react";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { Navigate, useLocation } from "react-router";
import * as Yup from "yup";
import { passwordResetAction } from "../../actions/userActions";
import { IpasswordResetPost } from "../../interfaces/passwordResetPost";
import { IRootState } from "../../interfaces/rootState";
import { SigninField } from "../Signin/SigninField";

const ForgotPassword: React.FC = (props: any) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const reset = (values: any) => {
        console.log("DO DISPATCH", {email: values.email});
        dispatch(passwordResetAction({payload: {email: values.email}}, undefined, undefined, "Password reset email sent", true));
    };
    return (
        <div className="LoginContainer">
            <Formik
                initialValues={{ email: "" }}
                validationSchema={Yup.object().shape({
                    email: Yup.string()
                        .email("Email is invalid")
                        .required("Email is required"),
                })}
                onSubmit={(values) => {
                    reset(values);
                }}
            >
                {({ values }) => (
                    <Form className="FormContainer">
                        <h2>Password Reset</h2>
                        <div>Please Enter your email.</div>
                        <div className="fields">
                            <Field name="email" placeholder="Email" component={SigninField} />
                        </div>
                        {/* <ErrorMessage name="email" component="div" className="invalid-feedback" />
                        {errorMessage && errorMessage.error && (
                            <div className="invalid-feedback">{errorMessage.error}</div>
                        )} */}
                        <Button className="signinButton" type="submit">Submit</Button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default ForgotPassword;
