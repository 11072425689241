import { omit } from "lodash";

export function autoReduce(action: any, state: any) {
  if (action.normalized) {
    Object.keys(action.normalized.entities).forEach((key) => {
      state = {
        ...state,
        [key]: {
          ...state[key],
          ...action?.normalized?.entities[key],
        },
      };
    });
  }
  if (action.removeKeys) {
    for (const removeKey of action.removeKeys) {
      state[removeKey.entities] = omit(state[removeKey.entities], removeKey.ids);
    }
  }
  if (action.overrides) {
    action.overrides.forEach((x: any) => {
      state[x.key] = x.value;
    });
  }
  if (action.mergeKeys) {
    action.mergeKeys.forEach((x: any) => {
      state[x.key] = {
        ...state[x.key],
        ...x.value,
      };
    });
  }
  if (action.forceStateRefresh) {
    console.log("forcing refresh?");
    return {
      ...state,
      force_state_refresh: state.force_state_refresh + 1,
    };
  }
  return state;
}
