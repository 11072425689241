import axios from "axios";
import { push } from "connected-react-router";
import { call, put } from "redux-saga/effects";
import { actionIds } from "../../actions/actionIds";
import { REACT_APP_SERVER_DOMAIN } from "../../constants";

async function solrSearchRequestAPI(payload: any) {
  try {
    const response = await axios.post(REACT_APP_SERVER_DOMAIN + "/solr/search", {
      payload: payload.solrSearch,
  });
    return response;
  } catch (e) {
    return e;
  }
}

async function caseLawSearchRequestAPI(payload: any) {
  try {
    const response = await axios.post(REACT_APP_SERVER_DOMAIN + "/solr/search-case-law", {
      payload: payload.caseLawSearch,
  });
    return response;
  } catch (e) {
    return e;
  }
}

async function solrRegulationsRequestAPI(payload: any) {
  try {
    const response = await axios.post(REACT_APP_SERVER_DOMAIN + "/solr/search_regulations", {
      payload: payload.solrRegulations,
  });
    return response;
  } catch (e) {
    return e;
  }
}

async function solrQuickRequestAPI(payload: any) {
  try {
    const response = await axios.post(REACT_APP_SERVER_DOMAIN + "/solr/search_quick_facts", {
      payload: payload.solrQuick,
  });
    return response;
  } catch (e) {
    return e;
  }
}

async function solrRelatedRequestAPI(payload: any) {
  try {
    const response = await axios.post(REACT_APP_SERVER_DOMAIN + "/solr/search_related", {
      payload: payload.solrRelated,
  });
    return response;
  } catch (e) {
    return e;
  }
}

async function getUserInfoRequestAPI(payload: any) {
  try {
    const response = await axios.get(REACT_APP_SERVER_DOMAIN + `/user/get_user_field_data?cookie=${payload.getUserInfo}`);
    return response;
  } catch (e) {
    return e;
  }
}

async function updateUserInfoRequestAPI(payload: any) {
  try {
    const response = await axios.put(REACT_APP_SERVER_DOMAIN + `/user/upsert_user_field_data`, {
      payload: payload.updateUserInfo,
    });
    return response;
  } catch (e) {
    return e;
  }
}

async function updateBasicUserInfoRequestAPI(payload: any) {
  try {
    const response = await axios.post(REACT_APP_SERVER_DOMAIN + `/user/upsert_user_data`, {
      payload: payload.updateBasicUserInfo,
    });
    return response;
  } catch (e) {
    return e;
  }
}

async function userPostRequestAPI(payload: any) {
  try {
    const response = await axios.post(REACT_APP_SERVER_DOMAIN + "/user/register", {
      payload: payload.userAction,
  });
    return response;
  } catch (e) {
    return e;
  }
}

const solrSearchSaga = {
  *solrSearchRequest(action: any) {
    let success = false;
    try {
      // @ts-ignore
      const response: any = yield call(solrSearchRequestAPI as any, action.payload as any);
      const successData = response.data;
      yield put({
          type: actionIds.SOLR_SEARCH_SUCCESS,
          data: response.data,
          is_initial: action.payload?.solrSearch?.is_initial
        });
      success = true;
      if (action.onSuccess) {
          action.onSuccess(successData);
        }
    } catch (e) {
      // tslint:disable-next-line
      console.log(e)
    }
    if (!success) {
      if (action.onFail) {
        action.onFail();
      }
    }
  },
  *caseLawSearchRequest(action: any) {
    let success = false;
    try {
      // @ts-ignore
      const response: any = yield call(caseLawSearchRequestAPI as any, action.payload as any);

      const successData = response.data;
      yield put({
          type: actionIds.CASE_LAW_SEARCH_SUCCESS,
          data: response.data,
          is_initial: action.payload?.caseLawSearch?.is_initial
        });
      success = true;
      if (action.onSuccess) {
          action.onSuccess(successData);
        }
    } catch (e) {
      // tslint:disable-next-line
      console.log(e)
    }
    if (!success) {
      if (action.onFail) {
        action.onFail();
      }
    }
  },
  *solrRegulationsRequest(action: any) {
    let success = false;
    let response;
    try {
      // @ts-ignore
      response = yield call(solrRegulationsRequestAPI, action.payload) as any;
      const successData = response.data;
      yield put({
          type: "SOLR_REGULATIONS_SUCCESS",
          data: response.data
        });
      success = true;
      if (action.onSuccess) {
          action.onSuccess(successData);
        }
    } catch (e) {
      // tslint:disable-next-line
      console.log(e)
    }
    if (!success) {
      if (action.onFail) {
        action.onFail();
      }
    }
  },
  *solrQuickRequest(action: any) {
    let success = false;
    let response;
    try {
      // @ts-ignore
      response = yield call(solrQuickRequestAPI, action.payload);
      const successData = response.data;
      success = true;
      yield put({
          type: "SOLR_QUICK_SUCCESS",
          data: response.data
        });
      if (action.onSuccess) {
          action.onSuccess(successData);
        }
    } catch (e) {
      // tslint:disable-next-line
      console.log(e)
    }
    if (!success) {
      if (action.onFail) {
        action.onFail();
      }
    }
  },
  *solrRelatedRequest(action: any) {
    let success = false;
    let response;
    try {
      // @ts-ignore
      response = yield call(solrRelatedRequestAPI, action.payload);
      const successData = response.data;
      success = true;
      yield put({
          type: "SOLR_RELATED_SUCCESS",
          data: response.data
        });
      if (action.onSuccess) {
          action.onSuccess(successData);
        }
    } catch (e) {
      // tslint:disable-next-line
      console.log(e)
    }
    if (!success) {
      if (action.onFail) {
        action.onFail();
      }
    }
  },
  *userPostRequest(action: any) {
    let success = false;
    let response;
    try {
      // @ts-ignore
      response = yield call(userPostRequestAPI, action.payload);
      const successData = response.data;
      success = true;
      if (action.onSuccess) {
          action.onSuccess(successData);
        }
    } catch (e) {
      // tslint:disable-next-line
      console.log(e)
    }
    if (!success) {
      if (action.onFail) {
        action.onFail();
      }
    }
  },

  *getUserInfoRequest(action: any) {
    let success = false;
    let response;
    try {
      // @ts-ignore
      response = yield call(getUserInfoRequestAPI, action.payload);
      const successData = response.data;
      success = true;
      yield put({
          type: "GET_USER_SUCCESS",
          data: response.data
        });
      if (action.onSuccess) {
          action.onSuccess(successData);
        }
    } catch (e) {
      // tslint:disable-next-line
      console.log(e)
    }
    if (!success) {
      if (action.onFail) {
        action.onFail();
      }
    }
  },
  *updateUserInfoRequest(action: any) {
    let success = false;
    let response;
    try {
      // @ts-ignore
      response = yield call(updateUserInfoRequestAPI, action.payload);
      const successData = response.data;
      success = true;
      if (action.onSuccess) {
          action.onSuccess(successData);
        }
    } catch (e) {
      // tslint:disable-next-line
      console.log(e)
    }
    if (!success) {
      if (action.onFail) {
        action.onFail();
      }
    }
  },
  *updateBasicUserInfoRequest(action: any) {
    let success = false;
    let response;
    try {
      // @ts-ignore
      response = yield call(updateBasicUserInfoRequestAPI, action.payload);
      const successData = response.data;
      success = true;
      if (action.onSuccess) {
          action.onSuccess(successData);
        }
    } catch (e) {
      // tslint:disable-next-line
      console.log(e)
    }
    if (!success) {
      if (action.onFail) {
        action.onFail();
      }
    }
  },

};

export default solrSearchSaga;
