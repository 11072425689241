import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { TextField } from "@mui/material/";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputBase from "@mui/material/InputBase";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import { FieldProps } from "formik";
import * as React from "react";

interface State {
  showPassword: boolean;
  passwordNotValid: boolean;
}

export const PasswordSetField: React.FC<FieldProps & TextFieldProps> = ({
  placeholder,
  field
}) => {
  const [values, setValues] = React.useState<State>({
    showPassword: false,
    passwordNotValid: true,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleErrorCheck = () => {
    return true;
  };
  return <InputBase
            // TODO DID THIS BREAK
            // onChange={handleErrorCheck}
            error={values.passwordNotValid}
            type={values.showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            placeholder={placeholder}
            {...field}
        />;
};
