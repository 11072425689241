import { ISolrQuickQuery } from "../interfaces/solrQuickQuery";
import { ISolrRegulationsQuery } from "../interfaces/solrRegulationsQuery";
import { ISolrRelatedQuery } from "../interfaces/solrRelatedQuery";
import { ISolrSearchQuery } from "../interfaces/solrSearchQuery";
import { actionIds, BaseAction } from "./actionIds";
import { genericAction } from "./genericAction";
/**
 * registration
 * @param userData
 */
export const solrSearchAction: (solrSearch: ISolrSearchQuery, onSuccess: any, onFail: any) => BaseAction = (solrSearch, onSuccess, onFail) => {
    const payload = {
        solrSearch,
    };
    return {
        type: actionIds.SOLR_SEARCH_FETCH,
        payload,
        onSuccess: onSuccess || null,
        onFail: onFail || null
    };
};

export const caseLawSearchAction: (caseLawSearch: ISolrSearchQuery, onSuccess: any, onFail: any) => BaseAction = (caseLawSearch, onSuccess, onFail) => {
    const payload = {
        caseLawSearch,
    };
    return {
        type: actionIds.CASE_LAW_SEARCH_FETCH,
        payload,
        onSuccess: onSuccess || null,
        onFail: onFail || null
    };
};

export const solrQuickAction: (solrQuick: ISolrQuickQuery, onSuccess: any, onFail: any) => BaseAction = (solrQuick, onSuccess, onFail) => {
    const payload = {
        solrQuick,
    };
    return {
        type: actionIds.SOLR_QUICK_FETCH,
        payload,
        onSuccess: onSuccess || null,
        onFail: onFail || null
    };
};

export const solrRelatedAction: (solrRelated: ISolrRelatedQuery, onSuccess: any, onFail: any) => BaseAction = (solrRelated, onSuccess, onFail) => {
    const payload = {
        solrRelated,
    };
    return {
        type: actionIds.SOLR_RELATED_FETCH,
        payload,
        onSuccess: onSuccess || null,
        onFail: onFail || null
    };
};

export const solrRegulationsAction: (solrRegulations: ISolrRegulationsQuery, onSuccess: any, onFail: any) => BaseAction = (solrRegulations, onSuccess, onFail) => {
    const payload = {
        solrRegulations,
    };
    return {
        type: actionIds.SOLR_REGULATIONS_FETCH,
        payload,
        onSuccess: onSuccess || null,
        onFail: onFail || null
    };
};

export const indexDocument = genericAction (actionIds.INDEX_DOCUMENT);
