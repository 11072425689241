import InputBase from "@mui/material/InputBase";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import { FieldProps } from "formik";
import * as React from "react";

export const SigninField: React.FC<FieldProps & TextFieldProps> = ({
  placeholder,
  field
}) => {
  return <InputBase placeholder={placeholder} {...field} />;
};
