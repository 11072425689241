import { Button, TextField } from "@mui/material/";
import * as React from "react";
import { connect } from "react-redux";

// tslint:disable-next-line
export interface Props {}

class TermsAndConditions extends React.Component<{}> {

  public render() {
    // tslint:disable-next-line
    return (
    <div className="TermsAndConditionsContainer">
        <div><h1><b>Terms and Conditions</b></h1>
        <p><span> </span></p>
        <p><b>Last Updated: 10/29/2019</b></p>
        <p>These terms and conditions are divided into the following parts:</p>
        <ul>
        <li><span>(A) General disclaimer of liability;</span></li>
        <li><span>(B) terms and conditions governing the use of this website;</span></li>
        </ul>
        <p><span>By using our website, you accept these terms and conditions in full. If you disagree with these terms and conditions or any part of these terms and conditions, you must not use our website. If you register with our website or buy any product or service from our website, we will ask you to expressly agree to these terms and conditions.</span></p>
        <p><span>Our website uses cookies. By using our website and agreeing to these terms and conditions, you consent to our use of cookies in accordance with the terms of our privacy policy.</span></p>
        <p>&nbsp;</p>
        <h2><b>(A) General disclaimer</b></h2>
        <p>&nbsp;</p>
        <h3><b>(1) Legal information</b></h3>
        <p><span>Much of the legal information on this website consists of summaries of complex legal issues. Legal and factual details and nuances are inevitably omitted from such summaries. Particular circumstances often radically affect the law that applies, and the way that the law applies.</span></p>
        <p><span>You should therefore never apply the legal information to your own situation without conducting additional research or engaging a lawyer. Nor should you assume that all of the relevant legal material is included on our website.</span></p>
        <p><span>MSHA regulations and guidance change frequently, and legal information may become out-of-date.</span></p>
        <p><span>Most of the legal information on this website relates to the United States and federally regulated MSHA statute and enforcement. However, this is not always the case. Do not assume that any particular item of legal information relates to any particular jurisdiction.</span></p>
        <p><span>For all of these reasons, you must not rely solely upon any information on this website, and we recommend that you take professional legal advice before embarking upon any course of action (or omitting to take any action) that has or may have legal implications.</span></p>
        <p>&nbsp;</p>
        <h3><b>(2) Exclusion of warranties, representations and guarantees</b></h3>
        <p><span>We do not warrant, represent or guarantee:</span></p>
        <ul>
        <li><span>the accuracy of the information published on this website;</span></li>
        <li><span>the completeness of the information published on this website;</span></li>
        <li><span>that the information published on this website is up-to-date;</span></li>
        <li><span>or the information on the website can be applied to achieve any particular result.</span></li>
        </ul>
        <p><span>To the maximum extent permitted by applicable law we exclude all representations, warranties and guarantees relating to this website and the use of this website (including, without limitation, any warranties implied by law of satisfactory quality, fitness for purpose and/or the use of reasonable care and skill).</span></p>
        <p>&nbsp;</p>
        <h3><b>(3) Limitations and exclusions of liability</b></h3>
        <p><span>Nothing in these terms and conditions will: (a) limit or exclude our or your liability for death or personal injury resulting from negligence; (b) limit or exclude our or your liability for fraud or fraudulent misrepresentation; (c) limit any of our or your liabilities in any way that is not permitted under applicable law; or (d) exclude any of our or your liabilities that may not be excluded under applicable law.</span></p>
        <p><span>The limitations and exclusions of liability set out in this Section and elsewhere in these terms and conditions: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the terms and conditions or in relation to the subject matter of the terms and conditions, including liabilities arising in contract, in tort (including negligence) and for breach of statutory duty.</span></p>
        <p><span>To the extent that the website and the information and services on the website are provided, we will not be liable for any loss or damage of any nature.</span></p>
        <p><span>We will not be liable to you in respect of any losses arising out of any event or events beyond our reasonable control.</span></p>
        <p><span>We will not be liable to you in respect of any business losses.</span></p>
        <p><span>We will not be liable to you in respect of any loss of or damage to profits, income, revenue, or anticipated savings.</span></p>
        <p><span>We will not be liable to you in respect of any loss of use or production.</span></p>
        <p><span>We will not be liable to you in respect of any loss of management time or office time.</span></p>
        <p><span>We will not be liable to you in respect of any loss of business, contracts, commercial opportunities or goodwill.</span></p>
        <p><span>We will not be liable to you in respect of any loss or corruption of any data, database or software.</span></p>
        <p><span>We will not be liable to you in respect of any special, indirect or consequential loss or damage.</span></p>
        <p><span>We will not be liable to you in respect of any losses arising out of any acts or omissions of any hosting services provider, payment services provider or other third party services provider.</span></p>
        <p><span>You accept that we have an interest in limiting the personal liability of our LLC’s members and employees. Having regard to that interest, you accept that we are a limited liability entity and agree that you will not bring any claim personally against individual members or employees in respect of any losses you suffer in connection with the website or these terms and conditions. This will not, of course, limit or exclude the liability of the LLC itself for the acts and omissions of our members and employees.</span></p>
        <p>&nbsp;</p>
        <h3><b>(4) Third party content</b></h3>
        <p><span>You acknowledge that some of the information published on this website is submitted or provided by users, law firm partners and other third parties, and that we do not usually review, approve or take editorial responsibility such information.</span></p>
        <p><span>You agree to the publication of comments, reviews and/or feedback relating to you, by others, on our website. You acknowledge that such comments, reviews and/or feedback may be critical or defamatory or otherwise unlawful; and you agree that you will not hold us liable in respect of any such comments, reviews and/or feedback, irrespective of whether we are aware or ought to he been aware of such comments, reviews and/or feedback.</span></p>
        <p>&nbsp;</p>
        <h3><b>(5) Website availability</b></h3>
        <p><span>From time to time the website or features of the website will be unavailable. Such unavailability may be the result of defects in the website software, scheduled or emergency maintenance procedures, or failures of third party service providers.</span></p>
        <p><span>We do not commit to ensuring that the website will be available at any particular time.</span></p>
        <p><span>Furthermore, we do not commit to ensure that the website will continue to be published in the future.</span></p>
        <p>&nbsp;</p>
        <h3><b>(6) Interactive features</b></h3>
        <p><span>Our website includes interactive features that allow users to communicate with us, our partner mine operators and our partner law firms. You acknowledge that, because of the limited nature of such communication, any legal assistance you may receive using any such facility is likely to be incomplete and may be misleading. Any legal assistance you may receive using any such facility does not constitute legal advice and accordingly should not be relied upon. No solicitor-client or attorney-client relationship shall be created through the use of our website.</span></p>
        <p>&nbsp;</p>
        <h3><b>(7) Our partners</b></h3>
        <p><span>In addition to protecting SEQ Legal LLP and its members and employees, the limitations of liability in these terms and conditions shall protect the following persons in respect of any claims made by any SEQ Legal LLP customer or any user of this website: (a) any SEQ Legal law firm partner; (b) any supplier of any legal document or documents made available on our website; (c) any supplier of other legal content made available on our website; and (d) any officer, employee, partner or member of any person referred to in sub-paragraphs (a) to (c). For the avoidance of doubt, this Section 8 shall not affect the terms of any solicitor-client or attorney-client relationship expressly agreed and entered into between a website user and any law firm.</span></p>
        <p><span> </span></p>
        <h2><b>(B) Terms and conditions of use</b></h2>
        <p><span> </span></p>
        <h3><b>(1) License to use website</b></h3>
        <p><span>Unless otherwise stated, we or our licensors own the intellectual property rights in the website and material on the website. Subject to the license below, all these intellectual property rights are reserved.</span></p>
        <p><span>You may view, download for caching purposes only, and print pages from the website for your own personal use, subject to the restrictions set out below and elsewhere in these terms and conditions of use. You must not: (a) republish material from this website (including republication on another website); (b) sell, rent or sub-license material from the website; (c) show any material from the website in public; (d) reproduce, duplicate, copy or otherwise exploit material on our website for a commercial purpose; (e) edit or otherwise modify any material on the website; or (f) redistribute material from this website, except for content specifically and expressly made available for redistribution (such as our newsletter).</span></p>
        <p><span>Where content is specifically made available for redistribution, it may only be redistributed within your organization.</span></p>
        <p><span> </span></p>
        <h3><b>(2) Acceptable use</b></h3>
        <p><span>You must not use our website in any way that causes, or may cause, damage to the website or impairment of the availability or accessibility of the website; or in any way which is unlawful, illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful purpose or activity. You must not use our website to copy, store, host, transmit, send, use, publish or distribute any material which consists of (or is linked to) any spyware, computer virus, Trojan horse, worm, keystroke logger, rootkit or other malicious computer software. You must not conduct any systematic or automated data collection activities (including without limitation scraping, data mining, data extraction and data harvesting)
        on or in relation to our website without our express written consent. You must not use our website to transmit or send unsolicited commercial communication. You must not use our website for any purposes related to marketing without our express written consent.</span></p>
        <p>&nbsp;</p>
        <h3><b>(3) Restricted access</b></h3>
        <p><span>Access to certain areas of our website is restricted. We reserve the right to restrict access to other areas of our website, or indeed our whole website, at our discretion. If we provide you with or you generate a password to enable you to access restricted areas of our website or other content or services, you must ensure that that password is kept confidential. You must notify us in writing immediately if you become aware of any unauthorized use of your account or password. You are responsible for any activity on our website arising out of any failure to keep your password confidential, and may be held liable for any losses arising out of such a failure. You must not use any other person&#8217;s user ID and password to access our website. We may disable your account on the website in our sole discretion without notice or explanation.</span></p>
    </div>
    </div>
  );
 }
}

export default connect(null, null)(TermsAndConditions);
