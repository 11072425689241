import { isArray } from "lodash";
import { normalize, schema } from "normalizr";
import { put } from "redux-saga/effects";

export const loggedUserSchema = new schema.Entity("loggedUser");
export const userSchema = new schema.Entity("users");
export const documentReferenceSchema = new schema.Entity("document_references");
export const systemSettingsSchema = new schema.Entity("system_settings");

export function autoYields(data: any): any[] {
  const yields = [] as any[];
  const pData = {
    loggedUser: loggedUserSchema,
    user: userSchema,
    users: [userSchema],
    document_reference: documentReferenceSchema,
    document_references: [documentReferenceSchema],
    system_settings: systemSettingsSchema,
  } as any;

  Object.keys(data).forEach((property: string) => {

    if (pData[property]) {
      switch (property) {
        case "loggedUser":
          window.localStorage.email = data[property].email;
          window.localStorage.name = data[property].name;
          window.localStorage.id = data[property].id;

          yields.push(
            put({
              type: "REDUCE_MERGE_" + property + "_SUCCESS",
              mergeKeys: [{
                key: "loggedUser",
                value: data[property]
              }],
            }),
          );
          break;
        case "system_settings":
          console.log("reduce system settings?", data[property])
          yields.push(
            put({
              type: "SYSTEM_SETTINGS_SET_SUCCESS",
              overrides: [{
                key: "system_settings",
                value: data[property]
              }],
            }),
          );
          break;
        default:
          console.log("property?", property)
          yields.push(
            put({
              type: "REDUCE_MERGE_" + property + "_SUCCESS",
              normalized: normalize(data[property], pData[property]),
            }),
          );
          break;
      }

    } else if (property.startsWith("deleted_")) { // check for deleted keys
      const compareString = property.replace("deleted_", "");
      if (pData[compareString]) {
        let deleteIds = data[property];
        let stateKey = "";
        let idAttribute = "";
        if (!isArray(deleteIds)) {
          deleteIds = [deleteIds];
        }
        if (isArray(pData[compareString])) {
          stateKey = pData[compareString][0]._key;
          idAttribute = pData[compareString][0]._idAttribute;
        } else {
          stateKey = pData[compareString]._key;
          idAttribute = pData[compareString]._idAttribute;
        }
        deleteIds = deleteIds.map((obj: any) => obj[idAttribute]);
        const removeKeys = [{ entities: stateKey, ids: deleteIds }];
        yields.push(put({ type: "REDUCE_DELETE_" + property + "_SUCCESS", removeKeys, forceStateRefresh: true}));
      }
    }
  });
  return yields;
}
