import * as React from "react";
import { useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from "@mui/material";

export interface IFacetItem {
  title: string;
  count: number;
  checked: boolean;
  label: string;
  indent?: boolean;
}

export interface IFacetListProps {
  hide_unchecked: boolean;
  minimized_count: number;
  maximized_count: number;
  facet_items: IFacetItem[];
  title: string;
  handleFacetChange: (identifier: string, facets: IFacetItem[]) => void;
  identifier: string;
  info_text: string;
}

const FacetList: React.FC<IFacetListProps> = (props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [items, setItems] = useState(props.facet_items.map(item => ({ ...item, checked: true })) as IFacetItem[]);
  const itemsToShow = isExpanded ? props.maximized_count : props.minimized_count;
  const totalPages = Math.ceil(items.length / itemsToShow);
  const [isPagingVisible, setIsPagingVisible] = useState(false);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(-1); // New state to track hovered row

  React.useEffect(() => {
    setItems(props.facet_items as any)
  }, [
    props.facet_items
  ])

  const handleSetItems = (newItems: IFacetItem[]) => {
    props.handleFacetChange(props.identifier, newItems);
    setItems(newItems);
  }
  const handleCheckChange = (index: number) => {
    const newItems = [...items];
    newItems[index].checked = !newItems[index].checked;
    handleSetItems(newItems);
  };

  const handleSelectAll = () => {
    //const newItems = items.map(item => ({ ...item, checked: true }));
    handleSetItems([]);
  };

  const handleShowMore = () => {
    setIsExpanded(true);
    setCurrentPage(1); // Reset to the first page
    if (items.length > props.maximized_count) {
      setIsPagingVisible(true); // Only show paging if more items than maximized_count
    }
  };

  const handleShowLess = () => {
    setIsExpanded(false);
    setCurrentPage(1); // Reset to the first page
    setIsPagingVisible(false);
  };

  const handleSelectOnly = (index: number) => {
    const newItems = items.map((item, idx) => ({
      ...item,
      checked: idx === index,
    }));
    handleSetItems(newItems);
  };

  const paginatedItems = items.slice((currentPage - 1) * itemsToShow, currentPage * itemsToShow);

  const replaceFaceSafeText = (text: any) => {
    if (!text) {
      return ""
    }
    text = text.replace(/_/g, ' ');
    text = text.replace(/replaceperiod/ig, '.');
    text = text.replace(/replaceampersand/ig, '&');
    return text;
  }

  return (
    <div style={{ border: '1px solid #cccccc', borderRadius: 8, padding: 8 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

        <b>{props.title} {props.info_text && <Tooltip title={props.info_text}>
          <IconButton>
            <InfoIcon />
          </IconButton>
        </Tooltip>}</b>
        <span style={{ textDecoration: 'underline', color: '#56A8C2', cursor: 'pointer' }} onClick={handleSelectAll}>Clear Filters</span>
      </div>
      <Table>
        <TableBody>
          {paginatedItems.map((item, index) => (
            ((!props.hide_unchecked || item.checked)) && <TableRow key={index}
              sx={{ '& > *': { borderBottom: 'unset', padding: 0 } }}
              onMouseEnter={() => setHoveredRowIndex(index)}
              onMouseLeave={() => setHoveredRowIndex(-1)}>
              <TableCell padding="checkbox">
                <Checkbox
                  sx={{
                    marginLeft: item.indent ? 3 : 0,
                    color: '#56A8C2', // Color for the unchecked state
                    '&.Mui-checked': {
                      color: '#56A8C2', // Color for the checked state
                    },
                    '&:hover': {
                      bgcolor: 'transparent', // Optional: change background color on hover
                    }
                  }}
                  checked={item.checked}
                  onChange={() => handleCheckChange(index + (currentPage - 1) * itemsToShow)}
                />
              </TableCell>
              <TableCell sx={{ paddingLeft: 0, paddingRight: 4, paddingTop: 1, paddingBottom: 1 }} align="left"><span style={{ fontSize: 12, textTransform: 'capitalize' }}>{replaceFaceSafeText(item.label) || replaceFaceSafeText(item.title)} ({item.count})</span></TableCell>

              <TableCell align="right" sx={{ borderBottom: 'none', padding: 0 }}>
                <span
                  style={{ cursor: 'pointer', color: '#56A8C2', visibility: hoveredRowIndex === index ? 'visible' : 'hidden' }}
                  onClick={() => handleSelectOnly(index + (currentPage - 1) * itemsToShow)}>Only</span>
              </TableCell>

            </TableRow>
          ))}
        </TableBody>
      </Table>
      {!isExpanded && items.length > props.minimized_count && (
        <div style={{ textAlign: 'center', marginTop: '10px' }}>
          <span style={{ cursor: 'pointer', color: '#56A8C2' }} onClick={handleShowMore}>Show More...</span>
        </div>
      )}
      {isExpanded && (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px', position: 'relative' }}>
          {currentPage > 1 && (
            <IconButton onClick={() => setCurrentPage(currentPage - 1)} style={{ position: 'absolute', left: 0 }}>
              <ArrowBackIosNewIcon />
            </IconButton>
          )}
          <span style={{ cursor: 'pointer', color: '#56A8C2' }} onClick={handleShowLess}>Show Less</span>
          {currentPage < totalPages && (
            <IconButton onClick={() => setCurrentPage(currentPage + 1)} style={{ position: 'absolute', right: 0 }}>
              <ArrowForwardIosIcon />
            </IconButton>
          )}
        </div>
      )}
    </div>
  );
};

export default FacetList;
